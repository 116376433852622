import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from '../constants/app-constant';

@Pipe({
  name: 'currencyPrice'
})
export class CurrencyPricePipe implements PipeTransform {

  transform(value: any): unknown {
    let numberValue = Number(value);
    if (isNaN(numberValue)) {
      return '';
    }
    let formattedValue = numberValue.toFixed(2);

    const locale = localStorage.getItem(AppConstants.LocalStorageKeys.language)
      ? localStorage.getItem(AppConstants.LocalStorageKeys.language).replace(/^"(.+(?="$))"$/, '$1')
      : 'en';

    const finalValue = new Intl.NumberFormat(locale, {
      useGrouping: true,
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(Number(formattedValue));

    return finalValue;
  }

}
