import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  transform(currency: string): unknown {
    const currencies = ['EUR', 'DKK', 'USD', 'GBP', 'CAD'];
    const symbols = ['€', 'DKK', '$', 'GBP', 'CAD'];
    const index = currencies.indexOf(currency);
    return index !== -1 ? symbols.at(index) : '-';
  }

}
